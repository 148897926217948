import { render, staticRenderFns } from "./copywriter.vue?vue&type=template&id=44d0b2d6&scoped=true"
import script from "./copywriter.vue?vue&type=script&lang=js"
export * from "./copywriter.vue?vue&type=script&lang=js"
import style0 from "./copywriter.vue?vue&type=style&index=0&id=44d0b2d6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44d0b2d6",
  null
  
)

export default component.exports