<template>
    <div class="imgForm">
        <el-form-item label="" prop="title">
            <emoji-input palce="请输入标题" type="text" v-model="currentCreative.copywriter.title"
                :val="currentCreative.copywriter.title" @input="setCreativeMsg('title')"></emoji-input>
        </el-form-item>
        <el-form-item label="" prop="content">
            <emoji-input palce="描述：添加其他详情" type="text" v-model="currentCreative.copywriter.content"
                :val="currentCreative.copywriter.content" @input="setCreativeMsg('content')"></emoji-input>
        </el-form-item>
        <el-form-item v-if="type == 'muti'">
            <el-input placeholder="请输入链接" v-model="currentCreative.link_url"></el-input>
        </el-form-item>
        <el-form-item label="" prop="message" v-else>
            <emoji-input palce="正文：介绍广告内容" type="textarea" v-model="currentCreative.message" :val="currentCreative.message"  @input="setCreativeMsg('message')"></emoji-input>
        </el-form-item>
        
    </div>
</template>
<script>
import emojiInput from '@/views/adManagement/createAd/components/emojiInput'
export default {
    props:{
        creative:{
            type:Object,
            default:()=>{}
        },
        type:{
            type:String,
            default:'img'
        }
    },
    data() {
        return {

        }
    },
    components:{
        emojiInput
    },
    computed:{
        currentCreative:{
            get(){
                return this.creative
            },
            set(){
                // this.setCreativeMsg()
            }
        }
    },
    methods:{
        setCreativeMsg(key){
            this.$emit('setCreativeMsg',this.currentCreative)
        }
    }
}
</script>
<style lang="scss" scoped>
.imgForm{
    .el-form-item{
        margin-bottom: 10px;
    }
}
</style>