
<template>
    <div>
        <el-tabs type="border-card" v-model="currentCreative.media_type" @tab-click="handleMedia"
            :before-leave="beforeLeaveMedia">
            <el-tab-pane label="单图" name="image">
                <upload-image :currentCreative="currentCreative" @setCreativeMsg="setCreativeMsg"
                    :isFbPageAd="isFbPageAd"  :edit="edit" @setPreviewIndex="setPreviewIndex"></upload-image>
            </el-tab-pane>
            <el-tab-pane label="轮播图" name="muti" v-if="!isFbPageAd">
                <muti :currentCreative="currentCreative" @setCreativeMsg="setCreativeMsg"></muti>
            </el-tab-pane>
            <el-tab-pane label="视频" name="video">
                <videos :currentCreative="currentCreative" @setCreativeMsg="setCreativeMsg" :isFbPageAd="isFbPageAd"
                    :rowThirdNum="rowThirdNum" :accountId="accountId" :edit="edit"  @setPreviewIndex="setPreviewIndex"></videos>
            </el-tab-pane>
            <el-tab-pane label="现有帖子" name="post" v-if="!isFbPageAd || isFbPagePostAd">
                <post :currentCreative="currentCreative" @setCreativeMsg="setCreativeMsg" ref="posts"></post>
            </el-tab-pane>
            <el-tab-pane label="动态广告" name="asset_feed" v-if="!isFbPageAd && isCustomSale">
                <more-source-ad :value="currentCreative" @input="setCreative"></more-source-ad>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import emojiInput from '../emojiInput';
import uploadVideo from '../uploadVideo'
import uploadImage from './uploadImage'
import muti from './muti.vue';
import videos from './video.vue';
import post from './post.vue';
import moreSourceAd from '../assetFeed/moreSourceAd.vue';
import {
    pagePosts,
} from '@/api/creatAd.js';
export default {
    props: {
        adSeriesInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },
        adsList: {
            type: Array,
            default: () => []
        },
        value: {
            type: Object,
            default: () => { }
        },
        isFbPageAd: {
            type: Boolean,
            default: false, //转化量
        },
        rowThirdNum: {
            type: String | Number,
            default: ''
        },
        accountId: {
            type: String | Number,
            default: 0,
        },
        edit:{
            type: Boolean,
            default: false,
        },
        index:{
            type: String | Number,
            default: 0,
        }
    },
    components: {
        emojiInput,
        uploadVideo,
        uploadImage,
        muti,
        videos,
        post,
        moreSourceAd
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapState('initData', ['initData']),
        currentCreative: {
            get() {
                return this.value
            },
            set(value) {
                this.setCreative(value)
            }
        },
        isCustomSale() {
            return this.adSeriesInfo.smart_promotion_type !== 'AUTOMATED_SHOPPING_ADS';
        },
        isFbPagePostAd() {
            return this.adSeriesInfo.objective == 'POST_ENGAGEMENT' || this.campaign_objective == 'POST_ENGAGEMENT';
        },
    },
    methods: {
        // 接收选择创意的index 
        setPreviewIndex(v){
            this.$emit('setPreviewIndex',v)
        },
        // 接收创意的改变
        setCreativeMsg(v) {
            this.currentCreative = { ...this.currentCreative, ...v }
        },
        // 改变创意
        setCreative(v) {
            this.$emit('setCreative', v,this.index)
        },
        // 样式切换之前
        beforeLeaveMedia(newTab, tab) {
            if (newTab == 'asset_feed' && this.adsList.length > 1) {
                this.$message({
                    type: 'warning',
                    message: '一个动态素材广告组下面不允许创建多条广告',
                });
                return false;
            } else {
                return true;
            }
        },
        // 点击切换广告样式
        async handleMedia(v) {
            if (v.name == 'asset_feed') {
                this.$set(this.currentCreative, 'fbAssetFeedSpec', {
                    titles: [''],
                    mDescriptions: [''],
                    mBodies: [''],
                    imgList: [],
                    videos: [],
                    mAdFormats: ['AUTOMATIC_FORMAT'],
                    actionTypes: this.initData.ad ? [this.initData.ad.CallToActionType[0].key] : [],
                    linkUrls: [this.adSeriesInfo.siteUrl],
                });
            } else {
                this.$set(this.currentCreative, 'fbAssetFeedSpec', null);
            }
            clearTimeout(this.timer);
            let text =
                v.name == 'image'
                    ? '单图'
                    : v.name == 'video'
                        ? '视频'
                        : v.name == 'post'
                            ? '帖子'
                            : v.name == 'asset_feed'
                                ? '动态广告'
                                : '轮播';
            this.$set(this.currentCreative, 'name', text + '' + (this.index + 1));
            if (v.name == 'post') {
                this.$refs.posts.fbHandleSearch()
                // this.getPostList(this.currentCreative.page_id);
            }
            this.$set(this.currentCreative, 'image', '');
            this.$set(this.currentCreative, 'videoStatus', false);
            this.$set(this.currentCreative, 'imgList', []);
            this.setCreative(this.currentCreative)
        },
        // 获取帖子列表
        getPostList(v) {
            this.$refs.posts.fbHandleSearch();
        },
    },
}
</script>
<style lang="scss" scoped></style>
