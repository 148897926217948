<template>
    <div>
        <p>推荐长宽比：1:1</p>
        <p>推荐尺寸：1080 * 1080像素</p>
        <p>最小尺寸：600 * 600像素</p>
        <p>图中文本内容不超过20%效果更佳</p>
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        }
    }
}
</script>
<style lang="scss" scoped>
    p{
        line-height: 24px;
        font-size: 12px;
        color: #666;
    }
</style>