<template>
    <div>
        <div class="swiper-con" v-if="creative.media_type == 'muti'">
            <draggable class="list-group" tag="ul" v-model="creative.mutiImgs" v-bind="dragOptions" :move="onMove"
                @start="isDragging = true" @end="isDragging = false">
                <transition-group type="transition" :name="'flip-list'">
                    <li class="list-group-item" v-for="(element, i) in creative.mutiImgs" :key="i + ''">
                        <span class="deleteMuti" @click="deleteMuti( i)"
                            v-if="i != creative.mutiImgs.length - 1"><i class="el-icon-close"></i></span>
                        <div class="adCon scrollItem" v-if="i != creative.mutiImgs.length - 1">
                            <div class="imgCon" v-if="!element.image">
                                <el-button size="small" @click="selectImg(i)">从创意云选择</el-button>
                                <el-upload class="upload-demo" action="#" :http-request="(file)=>upload_img(file,i)" :show-file-list="false"
                                    :before-upload="beforeUpload">
                                    <el-button size="small" type="primary">本地上传</el-button>
                                    <img-tips></img-tips>
                                </el-upload>
                            </div>
                            <div class="imgCon" v-else>
                                <div class="tools">
                                    <i class="el-icon-zoom-in"></i>
                                    <i class="el-icon-delete" @click="deleImg(i)"></i>
                                    <el-upload class="upload-demo" action="#" :http-request="(file)=>upload_img(file,i)"
                                        :show-file-list="false" style="display: inline-block" :before-upload="beforeUpload">
                                        <i class="el-icon-upload2"></i>
                                    </el-upload>
                                </div>
                                <!-- <img-tips></img-tips> -->
                                <el-image :src="element.image" :preview-src-list="[element.image]" fit="contain"></el-image>
                            </div>
                            <div class="imgForm">
                                <copywriter :creative="element" @setCreativeMsg="setImgMsg" type="muti"></copywriter>
                            </div>
                        </div>
                        <div class="addItem" v-if="i == creative.mutiImgs.length - 1" @click="addImgItem">
                            <i class="el-icon-circle-plus-outline"></i>
                            <p>添加素材</p>
                        </div>
                    </li>
                </transition-group>
            </draggable>
        </div>
        <img-list :imgListShow="imgListShow" @close="imgListShow = false" @urlList="urlList" uploadTypes="img"></img-list>
    </div>
</template>
<script>
import draggable from 'vuedraggable';
import tools from '../tools'
import adsMethods from '../../adsMethods'
import imgList from '@/views/adManagement/createAd/components/imgList'
import imgTips from './imgTips.vue'
import copywriter from './copywriter.vue'
export default {
    mixins: [adsMethods, tools],
    props: {
        currentCreative: {
            type: Object,
            default: () => { }
        }
    },
    components: {
        copywriter,
        imgTips,
        imgList,
        draggable
    },
    data() {
        return {
            editSlideable: true,
            isDragging: false,
            delayedDragging: false,
            uploadTypes:'img',
            currentIndex:0,
            imgListShow:false,
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: 'description',
                disabled: !this.editSlideable,
                ghostClass: 'ghost',
            };
        },
        creative:{
            get(){
                return this.currentCreative
            },
            set(){
                console.log('aaaaaaaaaaaaaaaaaaaaaaaaaa');
                this.setCreativeMsg()
            }
        }
    },
    methods: {
        // 监听拖拽
        onMove({ relatedContext, draggedContext }) {
            const relatedElement = relatedContext.element;
            const draggedElement = draggedContext.element;
            return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed;
        },
        setCreativeMsg() {
            this.$emit('setCreativeMsg', { ...this.creative})
        },
        // 添加轮播图
        addImgItem() {
            let obj = { name: '', fixed: false, copywriter: { title: '', content: '' } };
           this.creative.mutiImgs.push(obj);
        },
        selectImg(index){
            this.imgListShow = true;
            this.currentIndex = index;
        },
        // 删除轮播图
        deleteMuti(i) {
            if (this.creative.mutiImgs.length <= 2) {
                this.$message({
                    type: 'warning',
                    message: '不能再删除了',
                });
                return false;
            }
            this.creative.mutiImgs.splice(i, 1);
        },
        // 删除轮播图图片
        deleImg(i){
            this.creative.mutiImgs[i].image = '';
            this.setCreativeMsg()
        },
        setImgMsg(v, key) {
            // console.log(v, 'this.imgList, this.activeIndex, {...this.imgList[this.activeIndex]');
            // this.$set(this.imgList, this.activeIndex, { ...this.imgList[this.activeIndex], ...v });
        },
        // 获取素材库图片
        urlList(v) {
            let data = v[v.length-1]
            this.creative.mutiImgs[this.currentIndex].image = v[v.length-1].url;
            this.creative.mutiImgs[this.currentIndex].source_id = v[v.length-1].id;
        },
        // 上传图片
        upload_img(file,i) {
            this.uploadImg(file).then((data) => {
                this.creative.mutiImgs[i].image = data.data.url;
                this.creative.mutiImgs[i].source_id = data.data.id;
                this.setCreativeMsg()
            });
        },
    }
}
</script>
<style lang="scss" scoped>
</style>