<template>
    <div>
        <el-form-item lable="帖子id" prop="post_id" style="margin-bottom: 10px">
            <el-input placeholder="输入帖子id或者选择帖子" v-model="currentCreative.post_id"
                @change="(v => $emit('setPostId', v))"></el-input>
        </el-form-item>
        <el-table :data="postList" :empty-text="postMsg" border style="width: 100%; margin-top: 20px" ref="multipleTable"
            @row-click="rowClick" max-height="500px" size="mini" v-if="currentCreative.media_type == 'post'"
            v-loading="fbTableLoading">
            <el-table-column :width="300">
                <template slot="header" slot-scope="scope">
                    <el-input v-model="fbSearch" @keydown.native.enter="fbHandleSearch" size="mini"
                        placeholder="Facebook帖子,输入ID,回车搜索" />
                </template>
                <template slot-scope="scope">
                    <div class="pageItems">
                        <div class="pageImg">
                            <el-image :src="scope.row.picture" fit="contain"></el-image>
                        </div>
                        <div class="pageMsg">
                            <p>{{ scope.row.title }}</p>
                            <div>编号：{{ scope.row.post_id }}</div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="created_time" label="创建时间"></el-table-column>
            <el-table-column prop="call_to_action.type" label="行动号召"></el-table-column>
            <el-table-column prop="shares" label="点赞/分享/评论">
                <template slot-scope="scope">
                    <div class="pageItems">
                        {{ scope.row.likes }}/{{ scope.row.shares }}/{{ scope.row.comments }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="选择" :width="55">
                <template slot-scope="scope">
                    <el-radio :label="scope.row.post_id" v-model="currentCreative.post_id">&nbsp;</el-radio>
                </template>
            </el-table-column>
        </el-table>
        <div v-show="currentCreative.post_id">
            帖子Id：
            <span style="margin: 8px; color: #999">{{ currentCreative.post_id }}</span>
            <el-button type="text" size="mini" @click="lookPost(currentCreative.post_id)">查看帖子</el-button>
        </div>
    </div>
</template>
<script>
import {
    pagePosts,
    getPagePostById,
} from '@/api/creatAd.js';
export default {
    props: {
        currentCreative: {
            type: Object,
            default: () => { }
        },
        // postList: {
        //     type: Array,
        //     default: () => []
        // }
    },
    data() {
        return {
            postMsg: '暂无数据',
            postId: '',
            fbSearch: '',
            postList: [],
            fbTableLoading: false
        }
    },
    computed: {
        pageId() {
            return this.currentCreative.page_id;
        }
    },
    watch: {
        'this.currentCreative.page_id': {
            handler() {
                if (this.currentCreative.page_id) {
                    // this.fbHandleSearch()
                }
            },
            deep: true,
        }
    },
    methods: {
        // 点击帖子选中
        rowClick(row) {
            // console.log(row);
            this.postId = row.id;
        },
        async fbHandleSearch() {
            // fb 帖子关键字搜索
            let page_id = this.pageId;
            this.fbTableLoading = true;
            let res = null;
            if (this.fbSearch) {
                let request = {
                    postId: this.fbSearch,
                    pageId: page_id,
                };
                res = await getPagePostById(request, this.rowThirdNum);
            } else {
                res = await pagePosts({ pageId: page_id }, this.rowThirdNum);
            }
            this.fbTableLoading = false;
            if (res.code == 0) {
                if (this.fbSearch) {
                    this.postList = [res.data];
                    this.$nextTick(() => {
                        this.$emit('setCreativeMsg', { ...this.currentCreative, post_id: this.fbSearch })
                    });
                } else {
                    this.postList = res.data.list;
                }
            } else {
                (this.postList = []), (this.postMsg = res.data.comment);
            }
        },
        setPostId(v) {
            let reg = /\d+\/posts\/\d+/;
            if (v.match(reg)) {
                let postMsg = v.match(reg)[0];
                let postId = postMsg.split('/posts/')[0] + '_' + postMsg.split('/posts/')[1];
                this.$emit('setCreativeMsg', { ...this.currentCreative, post_id: post_id })
            } else {
                this.$emit('setCreativeMsg', { ...this.currentCreative, post_id: v })
            }
        },
        // 查看帖子
        lookPost(id) {
            window.open(`https://www.facebook.com/${id}`, '_blank');
        },
    }
}
</script>
<style lang="scss" scoped></style>